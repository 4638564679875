import React, { useState } from 'react';
import {
  Container,
  Box,
  List,
  ListItem,
  Divider,
  Grid,
  ListItemButton,
  Button,
  useMediaQuery,
  SwipeableDrawer,
  IconButton,
} from '@mui/material';
import {
  RootStyle,
  HeadLogo,
  HeaderLeft,
  NavBar,
  NavBarDesktop,
  NavBarMobile,
  HeaderRight,
  HeaderSign,
} from './styled';
import { Link, NavLink } from 'react-router-dom';
import { LanguagePopover } from '../../../components/language-popover';
import { LogoSection } from '../../../components/logo';
import { useAuthStore } from '../../../redux/features/authSlice';
import { AccountPopover } from '../../dashboard-layout/header/AccountPopover';
import { useResponsive } from '../../../hooks/useResponsive';
import { Icon } from '@iconify/react';

const navItems = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Rent',
    path: '/available-spaces',
  },
  {
    title: 'List Property',
    path: '/become-a-host',
  },
  {
    title: '3D Virtual Tour',
    path: '/3d-virtual-tour',
  },
  // {
  //   title: 'About us',
  //   path: '/about-us',
  // },
  // {
  //   title: 'FAQ',
  //   path: '/faq',
  // },
];

const NavigationItem = (props) => {
  let { openDrawer, setOpenDrawer, isLoggedin, loginInfo } = props;
  return (
    <List>
      {loginInfo.role !== 'landlord' ? (
        <>
          {navItems.map((val, index) => (
            <ListItem key={index}>
              <NavLink
                onClick={() => setOpenDrawer(false)}
                className="nav-link"
                to={val.path}
              >
                {val.title}
              </NavLink>
            </ListItem>
          ))}
        </>
      ) : null}
    </List>
  );
};

const DesktopNavigation = (props) => {
  let { openDrawer, setOpenDrawer, isLoggedin, loginInfo } = props;
  return (
    <NavBarDesktop>
      <NavBar>
        <NavigationItem
          isLoggedin={isLoggedin}
          loginInfo={loginInfo}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        />
      </NavBar>
    </NavBarDesktop>
  );
};
const MobileNavigation = (props) => {
  let { openDrawer, setOpenDrawer, isLoggedin, loginInfo } = props;
  return (
    <SwipeableDrawer
      anchor="top"
      open={openDrawer}
      onClose={() => setOpenDrawer(false)}
      onOpen={() => setOpenDrawer(true)}
    >
      <NavBarMobile>
        <NavBar>
          <NavigationItem
            isLoggedin={isLoggedin}
            loginInfo={loginInfo}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
          />
        </NavBar>
        {!isLoggedin && (
          <>
            <Divider flexItem sx={{ my: 3 }} />
            <Box sx={{ px: 3 }}>
              <Button
                variant="contained"
                color="primary"
                component={Link}
                size="large"
                to="/sign-up"
                fullWidth
                onClick={() => setOpenDrawer(false)}
              >
                Sign Up
              </Button>
              <Button
                variant="text"
                color="primary"
                size="large"
                component={Link}
                to="/login"
                fullWidth
                onClick={() => setOpenDrawer(false)}
              >
                Login
              </Button>
            </Box>
          </>
        )}
      </NavBarMobile>
    </SwipeableDrawer>
  );
};

export const Header = () => {
  let { isLoggedin, loginInfo } = useAuthStore();
  const [openDrawer, setOpenDrawer] = useState(false);
  const isMobileMode = useResponsive('down', 'lg');

  return (
    <RootStyle color="transparent" position="static">
      <Container fixed={true} sx={{ maxWidth: { xl: '1326px' } }}>
        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
          <Grid item lg={8} md={8} xs={4}>
            <HeaderLeft>
              <HeadLogo>
                <LogoSection
                  linkUrl={
                    loginInfo.role === 'landlord'
                      ? '/dashboard'
                      : loginInfo.role === 'renter'
                      ? '/'
                      : '/'
                  }
                />
              </HeadLogo>
              {isMobileMode ? (
                <MobileNavigation
                  isLoggedin={isLoggedin}
                  loginInfo={loginInfo}
                  openDrawer={openDrawer}
                  setOpenDrawer={setOpenDrawer}
                />
              ) : (
                <DesktopNavigation
                  isLoggedin={isLoggedin}
                  loginInfo={loginInfo}
                  openDrawer={openDrawer}
                  setOpenDrawer={setOpenDrawer}
                />
              )}
            </HeaderLeft>
          </Grid>
          <Grid item lg={4} md={4} xs={8}>
            <HeaderRight>
              {/* <Box sx={{ mr: { md: 2 } }}>
                <LanguagePopover />
              </Box> */}
              {isMobileMode ? (
                <>
                  {loginInfo.role !== 'landlord' && (
                    <IconButton
                      onClick={() => setOpenDrawer(!openDrawer)}
                      disableRipple
                    >
                      <Icon icon="ic:round-menu" />
                    </IconButton>
                  )}
                </>
              ) : (
                ''
              )}
              {isLoggedin ? (
                <AccountPopover />
              ) : (
                <>
                  {isMobileMode ? (
                    ''
                  ) : (
                    <>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                      />
                      <HeaderSign>
                        <Button
                          variant="text"
                          component={Link}
                          size="large"
                          to="/sign-up"
                        >
                          Sign Up
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          component={Link}
                          to="/login"
                        >
                          Login
                        </Button>
                      </HeaderSign>
                    </>
                  )}
                </>
              )}
            </HeaderRight>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
};
