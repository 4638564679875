import { useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
  responsiveFontSizes,
} from '@mui/material/styles';
import './App.css';
import Router from './Router';
import typography from './theme/typography';
import palette from './theme/palette';
import componentsOverride from './theme/overrides';
import shadows, { customShadows } from './theme/shadows';
import GlobalStyles from './theme/globalStyles';
import { ScrollToTop } from './components/scroll-to-top';
import { StoreProvider } from './redux/Store';
import { ToastContainer } from 'react-toastify';

function App() {
  const themeOptions = useMemo(
    () => ({
      typography,
      palette,
      shadows,
      customShadows,
    }),
    []
  );
  let theme = createTheme(themeOptions);
  theme = responsiveFontSizes(theme);
  theme.components = componentsOverride(theme);

  return (
    <StoreProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          <ScrollToTop />
          <div className="App">
            <Router />
          </div>
          <ToastContainer />
        </ThemeProvider>
      </StyledEngineProvider>
    </StoreProvider>
  );
}

export default App;
