import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../redux/features/authSlice';

const Navdata = () => {
  const history = useNavigate();
  //user permission modules
  const { loginAdminInfo } = useAuthStore();
  const [allPermissionModules, setAllPermissionModules] = useState([]);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isUsers, setIsUsers] = useState(false);
  const [isProperties, setIsProperties] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [isMasterCrud, setIsMasterCrud] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);
  const [isListPage, setIsListPage] = useState(false);
  const [isAboutUsPage, setIsAboutUsPage] = useState(false);

  const [iscurrentState, setIscurrentState] = useState('Dashboard');

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu');
      const iconItems = ul.querySelectorAll('.nav-icon.active');
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove('active');
        var id = item.getAttribute('subitems');
        if (document.getElementById(id))
          document.getElementById(id).classList.remove('show');
      });
    }
  }

  useEffect(() => {
    setAllPermissionModules(loginAdminInfo?.data);
    document.body.classList.remove('twocolumn-panel');
    if (iscurrentState !== 'Dashboard') {
      setIsDashboard(false);
    }
    if (iscurrentState !== 'Users') {
      setIsUsers(false);
    }
    if (iscurrentState !== 'Properties') {
      setIsProperties(false);
    }
    if (iscurrentState !== 'Payment') {
      setIsPayment(false);
    }
    if (iscurrentState !== 'Settings') {
      setIsSettings(false);
    }
    if (iscurrentState !== 'MasterCrud') {
      setIsMasterCrud(false);
    }
    if (iscurrentState !== 'Email') {
      setIsEmail(false);
    }
    if (iscurrentState !== 'Pages') {
      setIsPages(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isUsers,
    isProperties,
    isPayment,
    isSettings,
    isMasterCrud,
    isEmail,
    isPages,
  ]);

  const newMenueItem = [
    {
      id: 'dashboard',
      label: 'Dashboards',
      icon: 'ri-dashboard-2-line',
      link: '/admin/dashboard',
    },
    {
      id: 'virtual_tour',
      label: 'Virtual Tour',
      icon: 'ri-dashboard-2-line',
      link: '/admin/tour',
    },
  ];

  let settingMenue = {
    id: 'settings',
    label: 'Settings',
    icon: 'ri-settings-3-line',
    link: '/#',
    stateVariables: isSettings,
    click: function (e) {
      e.preventDefault();
      setIsSettings(!isSettings);
      setIscurrentState('Settings');
      updateIconSidebar(e);
    },
    subItems: [],
  };
  let masterCrudMenue = {
    id: 'masterCrud',
    label: 'Master Crud',
    icon: 'ri-pencil-ruler-2-line',
    link: '/#',
    click: function (e) {
      e.preventDefault();
      setIsMasterCrud(!isMasterCrud);
      setIscurrentState('MasterCrud');
      updateIconSidebar(e);
    },
    stateVariables: isMasterCrud,
    subItems: [],
  };

  let EmailMenue = {
    id: 'email',
    label: 'Email',
    icon: 'ri-mail-open-line',
    link: '/#',
    click: function (e) {
      e.preventDefault();
      setIsEmail(!isEmail);
      setIscurrentState('Email');
      updateIconSidebar(e);
    },
    stateVariables: isEmail,
    subItems: [],
  };

  let usersMenue = {
    id: 'users',
    label: 'Users',
    icon: 'ri-account-circle-line',
    link: '/admin/user-list',
  };
  let propertiesMenue = {
    id: 'properties',
    label: 'Properties',
    icon: 'ri-building-line',
    link: '/admin/property-list',
  };
  let chatMenue = {
    id: 'chat',
    label: 'Chat',
    icon: 'ri-chat-1-line',
    link: '/admin/chat',
  };
  let paymentMenue = {
    id: 'payment',
    label: 'Payment',
    icon: 'ri-bank-card-line',
    link: '/admin/payment',
  };

  allPermissionModules?.map((item) => {
    if (item?.users?.length > 0) {
      newMenueItem.push(usersMenue);
    } else if (item?.properties?.length > 0) {
      newMenueItem.push(propertiesMenue);
    } else if (item?.chat?.length > 0) {
      newMenueItem.push(chatMenue);
    } else if (item?.payment?.length > 0) {
      newMenueItem.push(paymentMenue);
    } else if (item?.setting?.length > 0) {
      item?.setting?.map((settingitem) => {
        if (settingitem.module_name == 'Reviews') {
          settingMenue.subItems.push({
            id: 'reviews',
            label: 'Reviews',
            link: '/admin/reviews',
            parentId: 'settings',
          });
        } else if (settingitem.module_name == 'Featured cities') {
          settingMenue.subItems.push({
            id: 'featuredCities',
            label: 'Featured Cities',
            link: '/admin/featured-cities',
            parentId: 'settings',
          });
        } else if (settingitem.module_name == 'Website info') {
          settingMenue.subItems.push({
            id: 'settings',
            label: 'Website Info',
            link: '/admin/settings',
            parentId: 'settings',
          });
        } else if (settingitem.module_name == 'Faqs') {
          settingMenue.subItems.push({
            id: 'faqs',
            label: 'FAQs',
            link: '/admin/faqs',
            parentId: 'settings',
          });
        } else if (settingitem.module_name == 'Amenities') {
          settingMenue.subItems.push({
            id: 'amenities',
            label: 'Amenities',
            link: '/admin/amenities',
            parentId: 'settings',
          });
        } else if (settingitem.module_name == 'Rules') {
          settingMenue.subItems.push({
            id: 'rules',
            label: 'Rules',
            link: '/admin/rules',
            parentId: 'settings',
          });
        } else if (settingitem.module_name == 'Explore rentals') {
          settingMenue.subItems.push({
            id: 'exploreRentals',
            label: 'Explore Rentals',
            link: '/admin/explore-rentals',
            parentId: 'settings',
          });
        }
      });
      newMenueItem.push(settingMenue);
    } else if (item?.masterCrud?.length > 0) {
      item?.masterCrud?.map((masterCruditem) => {
        if (masterCruditem.module_name == 'Home page') {
          masterCrudMenue.subItems.push({
            id: 'homePage',
            label: 'Home Page',
            link: '/admin/home?pageName=home&pageSize=7',
            parentId: 'masterCrud',
          });
        } else if (masterCruditem.module_name == 'Rent page') {
          masterCrudMenue.subItems.push({
            id: 'rentPage',
            label: 'Rent Page',
            link: '/admin/rent?pageName=rent&pageSize=3',
            parentId: 'masterCrud',
          });
        } else if (masterCruditem.module_name == 'List page') {
          masterCrudMenue.subItems.push({
            id: 'listPage',
            label: 'List Page',
            link: '/admin/list?pageName=list&pageSize=5',
            parentId: 'masterCrud',
          });
        } else if (masterCruditem.module_name == 'Aboutus page') {
          masterCrudMenue.subItems.push({
            id: 'aboutUs',
            label: 'About Us page',
            link: '/admin/about-us?pageName=aboutUs&pageSize=3',
            parentId: 'masterCrud',
          });
        }
      });
      newMenueItem.push(masterCrudMenue);
    } else if (item?.emailModule?.length > 0) {
      item?.emailModule?.map((emailModuleitem) => {
        if (emailModuleitem.module_name == 'Email templates') {
          EmailMenue.subItems.push({
            id: 'emailTemplates',
            label: 'Email Templates',
            link: '/admin/email-templates',
            parentId: 'email',
          });
        }
      });
      newMenueItem.push(EmailMenue);
    }
  });
  const menuItems = [
    {
      label: 'Menu',
      isHeader: true,
    },
    {
      id: 'dashboard',
      label: 'Dashboards',
      icon: 'ri-dashboard-2-line',
      link: '/admin/dashboard',
    },
    {
      id: 'users',
      label: 'Users',
      icon: 'ri-account-circle-line',
      link: '/admin/user-list',
    },
    {
      id: 'properties',
      label: 'Properties',
      icon: 'ri-building-line',
      link: '/admin/property-list',
    },
    {
      id: 'chat',
      label: 'Chat',
      icon: 'ri-chat-1-line',
      link: '/admin/chat',
    },
    {
      id: 'payment',
      label: 'Payment',
      icon: 'ri-bank-card-line',
      link: '/admin/payment',
    },
    {
      label: 'Settings',
      isHeader: true,
    },
    {
      id: 'settings',
      label: 'Settings',
      icon: 'ri-settings-3-line',
      link: '/#',
      stateVariables: isSettings,
      click: function (e) {
        e.preventDefault();
        setIsSettings(!isSettings);
        setIscurrentState('Settings');
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: 'settings',
          label: 'Website Info',
          link: '/admin/settings',
          parentId: 'settings',
        },
        {
          id: 'faqs',
          label: 'FAQs',
          link: '/admin/faqs',
          parentId: 'settings',
        },
        {
          id: 'amenities',
          label: 'Amenities',
          link: '/admin/amenities',
          parentId: 'settings',
        },
        {
          id: 'rules',
          label: 'Rules',
          link: '/admin/rules',
          parentId: 'settings',
        },
        {
          id: 'exploreRentals',
          label: 'Explore Rentals',
          link: '/admin/explore-rentals',
          parentId: 'settings',
        },
        {
          id: 'featuredCities',
          label: 'Featured Cities',
          link: '/admin/featured-cities',
          parentId: 'settings',
        },
        {
          id: 'reviews',
          label: 'Reviews',
          link: '/admin/reviews',
          parentId: 'settings',
        },
      ],
    },
    {
      id: 'masterCrud',
      label: 'Master Crud',
      icon: 'ri-pencil-ruler-2-line',
      link: '/#',
      click: function (e) {
        e.preventDefault();
        setIsMasterCrud(!isMasterCrud);
        setIscurrentState('MasterCrud');
        updateIconSidebar(e);
      },
      stateVariables: isMasterCrud,
      subItems: [
        {
          id: 'homePage',
          label: 'Home Page',
          link: '/admin/home?pageName=home&pageSize=7',
          parentId: 'masterCrud',
        },
        {
          id: 'rentPage',
          label: 'Rent Page',
          link: '/admin/rent?pageName=rent&pageSize=3',
          parentId: 'masterCrud',
        },
        {
          id: 'listPage',
          label: 'List Page',
          link: '/admin/list?pageName=list&pageSize=5',
          parentId: 'masterCrud',
        },
        {
          id: 'aboutUs',
          label: 'About Us page',
          link: '/admin/about-us?pageName=aboutUs&pageSize=3',
          parentId: 'masterCrud',
        },
      ],
    },
    {
      id: 'email',
      label: 'Email',
      icon: 'ri-mail-open-line',
      link: '/#',
      click: function (e) {
        e.preventDefault();
        setIsEmail(!isEmail);
        setIscurrentState('Email');
        updateIconSidebar(e);
      },
      stateVariables: isEmail,
      subItems: [
        {
          id: 'emailTemplates',
          label: 'Email Templates',
          link: '/admin/email-templates',
          parentId: 'email',
        },
      ],
    },
    {
      label: 'Components',
      isHeader: true,
    },
  ];

  return loginAdminInfo?.role === 'superadmin' ? (
    <React.Fragment>{menuItems}</React.Fragment>
  ) : (
    <React.Fragment>{newMenueItem}</React.Fragment>
  );
};
export default Navdata;
