import React from 'react';
import IconFeature from './IconFeature';
import { ReactComponent as DocumentValidationIcon } from '../../assets/virtual-tour/DocumentValidation.svg';
import { ReactComponent as CalendarIcon } from '../../assets/virtual-tour/Calendar.svg';
import { ReactComponent as CameraIcon } from '../../assets/virtual-tour/Camera.svg';
import { ReactComponent as ThreeDViewIcon } from '../../assets/virtual-tour/3DView.svg';
import { ReactComponent as DottedArrowDownIcon } from '../../assets/virtual-tour/DottedArrowDown.svg';
import { ReactComponent as DottedArrowUpIcon } from '../../assets/virtual-tour/DottedArrowUp.svg';
import { Grid } from '@mui/material';
import { useResponsive } from '../../hooks/useResponsive';

const HowItWorks = () => {
  const isMobileMode = useResponsive('down', 'sm');
  const isTabletMode = useResponsive('only', 'md');

  return (
    <Grid container spacing={1} style={styles.container}>
      <Grid item xs={12} md={2} style={styles.featureWrapper}>
        <IconFeature
          svgIcon={<DocumentValidationIcon style={styles.icon} />}
          header="Sign Up"
          description="Click the button below to reserve your free 3D photography session."
          headerStyle={styles.header}
          descriptionStyle={styles.description}
          wrapperStyle={{ padding: '0' }}
        />
      </Grid>

      {!isMobileMode && !isTabletMode && (
        <Grid item xs={1} style={styles.arrowWrapper}>
          <DottedArrowDownIcon style={styles.arrowIcon} />
        </Grid>
      )}

      <Grid item xs={12} md={2} style={styles.featureWrapper}>
        <IconFeature
          svgIcon={<CalendarIcon style={styles.icon} />}
          header="Scheduled"
          description="We’ll contact you to arrange a convenient time."
          headerStyle={styles.header}
          wrapperStyle={{ padding: '0' }}
          descriptionStyle={styles.description}
        />
      </Grid>

      {!isMobileMode && !isTabletMode && (
        <Grid item xs={1} style={styles.arrowWrapper}>
          <DottedArrowUpIcon style={styles.arrowIcon} />
        </Grid>
      )}

      <Grid item xs={12} md={2} style={styles.featureWrapper}>
        <IconFeature
          svgIcon={<CameraIcon style={styles.icon} />}
          header="Shoot"
          description="Our expert team captures your property’s best features."
          headerStyle={styles.header}
          wrapperStyle={{ padding: '0' }}
          descriptionStyle={styles.description}
        />
      </Grid>

      {!isMobileMode && !isTabletMode && (
        <Grid item xs={1} style={styles.arrowWrapper}>
          <DottedArrowDownIcon style={styles.arrowIcon} />
        </Grid>
      )}

      <Grid item xs={12} md={2} style={styles.featureWrapper}>
        <IconFeature
          svgIcon={<ThreeDViewIcon style={styles.icon} />}
          header="Showcase"
          description="Receive your immersive 3D tour to share online."
          headerStyle={styles.header}
          wrapperStyle={{ padding: '0' }}
          descriptionStyle={styles.description}
        />
      </Grid>
    </Grid>
  );
};

export default HowItWorks;

const styles = {
  container: {
    padding: '1rem',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  icon: {
    width: '3rem',
    height: '3rem',
  },
  arrowIcon: {},
  featureWrapper: {
    textAlign: 'center',
  },
  arrowWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    fontSize: '1rem',
    fontWeight: 600,
    marginBottom: '16px',
  },
  description: {
    fontSize: '0.875rem',
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: '40px',
  },
};
