export const propertyOptions = [
  {
    label: 'Apartment',
    value: 'Apartment',
  },
  {
    label: 'Bungalow',
    value: 'Bungalow',
  },
  {
    label: 'Compound House',
    value: 'Compound House',
  },
  {
    label: 'Detached House',
    value: 'Detached House',
  },
  {
    label: 'Duplexes',
    value: 'Duplexes',
  },
  {
    label: 'Semi-detached',
    value: 'Semi-detached',
  },
  {
    label: 'Townhouse',
    value: 'Townhouse',
  },
  {
    label: 'Triplexes/Quadplexes',
    value: 'Triplexes/Quadplexes',
  },
  {
    label: 'Villa',
    value: 'Villa',
  },
];

export const accountTypeOptions = [
  {
    label: 'Personal',
    value: 'personal',
  },
  {
    label: 'Business',
    value: 'business',
  },
];

export const documentTypeOptions = [
  {
    label: 'Identity Number',
    value: 'identityNumber',
  },
  {
    label: 'Passport Number',
    value: 'passportNumber',
  },
  {
    label: 'Business Registration Number',
    value: 'businessRegistrationNumber',
  },
];

export const priceOptions = [
  {
    label: '1 Month',
    value: 'month',
  },
  {
    label: '3 Month',
    value: 'threeMonth',
  },
  {
    label: '6 Month',
    value: 'sixMonth',
  },
  {
    label: '1 Year',
    value: 'year',
  },
];

export const nigerianStates = [
  { label: 'Abia', value: 'abia' },
  { label: 'Abuja', value: 'abuja' },
  { label: 'Adamawa', value: 'adamawa' },
  { label: 'Akwa Ibom', value: 'akwa ibom' },
  { label: 'Anambra', value: 'anambra' },
  { label: 'Bauchi', value: 'bauchi' },
  { label: 'Bayelsa', value: 'bayelsa' },
  { label: 'Benue', value: 'benue' },
  { label: 'Borno', value: 'borno' },
  { label: 'Cross River', value: 'cross river' },
  { label: 'Delta', value: 'delta' },
  { label: 'Ebonyi', value: 'ebonyi' },
  { label: 'Edo', value: 'edo' },
  { label: 'Ekiti', value: 'ekiti' },
  { label: 'Enugu', value: 'enugu' },
  { label: 'Gombe', value: 'gombe' },
  { label: 'Imo', value: 'imo' },
  { label: 'Jigawa', value: 'jigawa' },
  { label: 'Kaduna', value: 'kaduna' },
  { label: 'Kano', value: 'kano' },
  { label: 'Katsina', value: 'katsina' },
  { label: 'Kebbi', value: 'kebbi' },
  { label: 'Kogi', value: 'kogi' },
  { label: 'Kwara', value: 'kwara' },
  { label: 'Lagos', value: 'lagos' },
  { label: 'Nasarawa', value: 'nasarawa' },
  { label: 'Niger', value: 'niger' },
  { label: 'Ogun', value: 'ogun' },
  { label: 'Ondo', value: 'ondo' },
  { label: 'Osun', value: 'osun' },
  { label: 'Oyo', value: 'oyo' },
  { label: 'Plateau', value: 'plateau' },
  { label: 'Rivers', value: 'rivers' },
  { label: 'Sokoto', value: 'sokoto' },
  { label: 'Taraba', value: 'taraba' },
  { label: 'Yobe', value: 'yobe' },
  { label: 'Zamfara', value: 'zamfara' },
];

export const ghanaRegions = [
  { label: 'Accra', value: 'accra' },
  { label: 'Ashanti', value: 'ashanti' },
  { label: 'Brong Ahafo', value: 'brong ahafo' },
  { label: 'Central Region', value: 'central region' },
  { label: 'Eastern Region', value: 'eastern region' },
  { label: 'Northern Region', value: 'northern region' },
  { label: 'Upper East Region', value: 'upper east region' },
  { label: 'Upper West Region', value: 'upper west region' },
  { label: 'Volta Region', value: 'volta region' },
  { label: 'Western Region', value: 'western region' },
];
