import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const IconFeature = (props) => {
  return (
    <Box sx={[styles.container, props.wrapperStyle]}>
      <Box sx={styles.iconContainer}>{props.svgIcon}</Box>
      <Typography variant="h1" sx={[styles.header, props.headerStyle]}>
        {props.header}
      </Typography>
      <Typography
        variant="body1"
        sx={[styles.description, props.descriptionStyle]}
      >
        {props.description}
      </Typography>
    </Box>
  );
};

export default IconFeature;

IconFeature.propTypes = {
  svgIcon: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  wrapperStyle: PropTypes.object,
  headerStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
};

const styles = {
  container: {
    padding: '2rem 2.5rem',
    ['@media (max-width:600px)']: {
      borderRight: 'none',
      borderBottom: '1px solid #FEAE01',
      padding: '1.5rem',
    },
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '16px',
  },
  header: {
    fontSize: '1.5rem !important',
    fontWeight: 600,
    marginBottom: '16px',
    marginTop: '16px',
  },
  description: {
    fontSize: '1.125rem',
    fontWeight: 400,
    marginBottom: '32px',
  },
};
