import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ButtonBase, Typography, Box } from '@mui/material';
import { useAuthStore } from '../../redux/features/authSlice';
import { useEffect, useState } from 'react';
import { getLogo, getLogoCommon } from '../../services/apiServices';
import { IMG_URL } from '../../utils/constant';
import logo from '../../assets/icons/Logo (1).png';

export const LogoSection = ({ color, to }) => {
  let { loginInfo } = useAuthStore();
  // const [logoData, setLogoData] = useState();
  // const [fetchSingleUserState, setFetchSingleUserState] = useState({
  //   message: '',
  //   spinFlag: false,
  //   showAlert: false,
  //   showAlertError: false,
  // });
  // useEffect(() => {
  //   getLogoHeader();
  // }, []);
  //
  // const getLogoHeader = async () => {
  //   try {
  //     setFetchSingleUserState({
  //       ...fetchSingleUserState,
  //       spinFlag: true,
  //       showAlert: false,
  //     });
  //     const { data } = await getLogoCommon();
  //     if (data.statusCode === 200) {
  //       console.log('dtdtdt', data);
  //       setLogoData(data.logo);
  //       setFetchSingleUserState({
  //         ...fetchSingleUserState,
  //         message: data.message,
  //         spinFlag: false,
  //         showAlert: false,
  //       });
  //       console.log('dwshcwsgchg', data);
  //     } else {
  //       setFetchSingleUserState({
  //         ...fetchSingleUserState,
  //         message: data.message,
  //         spinFlag: false,
  //         showAlert: true,
  //       });
  //     }
  //   } catch (err) {
  //     console.log('erro', err);
  //   }
  // };
  return (
    <ButtonBase
      disableRipple
      component={Link}
      color={color}
      to={loginInfo.role === 'landlord' ? '/dashboard' : '/'}
      sx={{ maxWidth: '155px' }}
    >
      {/* <SvgColor
        src={flatttsLogo}
        alt="Flattts Logo"
        sx={{ height: 'auto', width: 'auto' }}
      /> */}
      {/* <img src={flatttsLogo} alt="Flattts Logo" /> */}
      {/*<img src={`${IMG_URL}${logoData?.logo}`} alt="Flattts Logo" />*/}
      <img src={logo} alt="Flattts Logo" />
    </ButtonBase>
  );
};
