import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import HeaderAndDescription from './HeaderAndDescription';
import { ReactComponent as ArrowIconLeft } from '../../assets/icons/ArrowLeftNoCircle.svg';

const testimonials = [
  {
    id: 1,
    text: 'I was blown away by the FLATTTS 3D Home app! It transformed the way I visualize my space. The ease of use and stunning graphics made my home design process so much fun. I highly recommend it to anyone looking to renovate or decorate!',
    author: 'Ugochi, Interior Designer',
  },
  {
    id: 2,
    text: 'Using FLATTTS for virtual tours has made my property listings incredibly popular. Prospective buyers can explore properties online like never before—it’s been a huge boost to my business.',
    author: 'Tunde Bakare, Realtor',
  },
  {
    id: 3,
    text: 'I saw a 70% increase in inquiries on my property listings after including interactive floor plans and 3D tours with FLATTTS. It’s a must-have tool for every agent!',
    author: 'Chinelo Okafor, Real Estate Agent',
  },
];

const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  return (
    <Box sx={styles.container}>
      <HeaderAndDescription showHeaderOnly header="What people are saying" />

      <Box sx={styles.testimonialContainer}>
        <IconButton onClick={handlePrev} sx={styles.arrowButton}>
          <ArrowIconLeft />
        </IconButton>

        <Box sx={styles.testimonialContent}>
          <Typography variant="body1" sx={styles.testimonialText}>
            {testimonials[activeIndex].text}
          </Typography>
          <Typography variant="subtitle1" sx={styles.testimonialAuthor}>
            - {testimonials[activeIndex].author}
          </Typography>
        </Box>

        <IconButton onClick={handleNext} sx={styles.arrowButton}>
          <ArrowIconLeft style={{ transform: 'rotate(180deg)' }} />
        </IconButton>
      </Box>

      <Box sx={styles.dotsContainer}>
        {testimonials.map((_, index) => (
          <Box
            key={index}
            sx={{
              ...styles.dot,
              backgroundColor: activeIndex === index ? '#FEAE01' : '',
              border: activeIndex === index ? '' : '1px solid #FEAE01',
            }}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Testimonials;

const styles = {
  container: {
    textAlign: 'center',
    padding: '1rem',
  },
  testimonialContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '2rem 0',
    flexDirection: { xs: 'column', sm: 'row' },
  },
  arrowButton: {
    color: '#141313',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    margin: { xs: '0.5rem', sm: '0' },
  },
  testimonialContent: {
    flex: 1,
    textAlign: 'center',
    padding: '1rem',
    backgroundColor: '#FFF9EB',
    overflow: 'hidden',
    fontSize: { xs: '0.9rem', sm: '1rem' },
  },
  testimonialText: {
    fontSize: { xs: '1rem', sm: '1.125rem' },
    fontStyle: 'italic',
    marginBottom: '1rem',
  },
  testimonialAuthor: {
    fontWeight: 600,
    color: '#555',
    fontSize: { xs: '0.85rem', sm: '1rem' },
  },
  dotsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  dot: {
    width: { xs: '8px', sm: '10px' },
    height: { xs: '8px', sm: '10px' },
    borderRadius: '50%',
    margin: '0 5px',
    cursor: 'pointer',
    aspectRatio: '1',
    transition: 'background-color 0.3s',
  },
};
