import {
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  Box,
  TableBody,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { $http } from '../../../utils/axiosClient';
import { toast } from 'react-toastify';

export default function AdminVirtualTour() {
  const [tours, setTours] = useState([]);
  const getTours = async () => {
    try {
      const { data } = await $http.get('/general/tour');
      setTours(data.data);
    } catch (error) {
      toast.error('An error occurred');
    }
  };

  useEffect(() => {
    getTours();
  }, []);
  return (
    <div className="pt-[80px] px-3">
      <h4 className="fs-16 mb-6">Virtual Tour</h4>

      <Box className={'bg-white'}>
        <TableContainer sx={{ minWidth: 1100 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align={'left'}>Name</TableCell>
                <TableCell align={'left'}>City</TableCell>
                <TableCell align={'left'}>No of Properties</TableCell>
                <TableCell align={'left'}>Phone Number</TableCell>
                <TableCell align={'left'}>Company Website</TableCell>
                <TableCell align={'left'}>Date to Take Photos</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tours.map((tour) => (
                <TableRow>
                  <TableCell align={'left'}>{tour.name}</TableCell>
                  <TableCell align={'left'}>{tour.city}</TableCell>
                  <TableCell align={'left'}>{tour.noOfProperties}</TableCell>
                  <TableCell align={'left'}>{tour.phoneNumber}</TableCell>
                  <TableCell align={'left'}>{tour.companyWebsite}</TableCell>
                  <TableCell align={'left'}>{tour.dateToTakePhotos}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
