import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const HeaderAndDescription = (props) => {
  return (
    <Box>
      <Typography variant="h1" sx={[styles.header, props.headerStyle]}>
        {props.header}
      </Typography>
      {!props.showHeaderOnly && (
        <Typography
          variant="body1"
          sx={[styles.description, props.descriptionStyle]}
        >
          {props.description}
        </Typography>
      )}
    </Box>
  );
};

export default HeaderAndDescription;

HeaderAndDescription.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  headerStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  showHeaderOnly: PropTypes.bool,
};

const styles = {
  header: {
    fontSize: '2.25rem !important',
    fontWeight: 600,
    marginBottom: '16px',
  },
  description: {
    fontSize: '1.125rem',
    fontWeight: 400,
    marginBottom: '32px',
  },
};
