import React from 'react';
import { Button, Dialog, TextField } from '@mui/material';
import { ReactComponent as XIcon } from '../../assets/virtual-tour/x-close.svg';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Yup from 'yup';
import { Formik } from 'formik';
import InputErrorText from '../shared/InputErrorText';
import { $http } from '../../utils/axiosClient';
import { toast } from 'react-toastify';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

const schema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  city: Yup.string().required('City is required'),
  noOfProperties: Yup.string()
    .required('Number of Properties is required')
    .matches(/^\d+$/, 'Number of Properties must be a valid number'),
  phoneNumber: Yup.string()
    .required('Phone Number is required')
    .matches(
      /^(\+?[1-9]{1,3})?\d{10}$/,
      'Phone Number must be valid. Include the country code if applicable (e.g., +234XXXXXXXXXX).'
    ),
  companyWebsite: Yup.string()
    .nullable()
    .url('Company Website must be a valid URL e.g., https://example.com'),
  dateToTakePhotos: Yup.string()
    .nullable()
    .test(
      'is-after-min-date',
      'Date to take photos must be from January 2025 onwards',
      (value) => {
        if (!value) return true; // Allow null or empty values
        const selectedDate = dayjs(value);
        const minDate = dayjs('2025-01-01');
        return selectedDate.isSameOrAfter(minDate);
      }
    ),
});

export default function ReserveSpotDialog({ open, setOpen }) {
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      await $http.post('/general/tour', values);
      toast.success('Virtual tour photos scheduled successfully');
      setOpen(false);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      className="max-w-full"
      onClose={() => setOpen(false)}
    >
      <div className="py-6 px-4 md:px-8 bg-[#FFF9EB] w-full rounded-lg">
        <div className="flex justify-end mb-8">
          <button className="ml-auto p-0" onClick={() => setOpen(false)}>
            <XIcon />
          </button>
        </div>

        <h3 className="font-semibold text-lg md:text-xl text-center max-w-xl mx-auto">
          Please enter your details so we can schedule your free virtual tour
          photos
        </h3>

        <Formik
          initialValues={{
            name: '',
            city: '',
            noOfProperties: '',
            phoneNumber: '',
            companyWebsite: '',
            dateToTakePhotos: '',
          }}
          onSubmit={onSubmit}
          validationSchema={schema}
        >
          {({
            values,
            handleSubmit,
            errors,
            handleBlur,
            handleChange,
            setFieldValue,
          }) => (
            <form
              className="flex flex-col gap-4 mt-8 max-w-md mx-auto"
              onSubmit={handleSubmit}
            >
              <InputErrorText errorMessage={errors.name}>
                <TextField
                  fullWidth
                  sx={styles.input}
                  label="Name"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </InputErrorText>

              <InputErrorText errorMessage={errors.city}>
                <TextField
                  fullWidth
                  sx={styles.input}
                  label="City"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                />
              </InputErrorText>

              <InputErrorText errorMessage={errors.noOfProperties}>
                <TextField
                  fullWidth
                  sx={styles.input}
                  label="Number of Properties"
                  name="noOfProperties"
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, '');
                    setFieldValue('noOfProperties', value);
                  }}
                  onBlur={handleBlur}
                  value={values.noOfProperties}
                />
              </InputErrorText>

              <InputErrorText errorMessage={errors.phoneNumber}>
                <TextField
                  fullWidth
                  sx={styles.input}
                  label="Phone Number"
                  name="phoneNumber"
                  inputProps={{
                    inputMode: 'tel',
                  }}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^+\d]/g, ''); // Allow digits and "+"
                    setFieldValue('phoneNumber', value);
                  }}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                />
              </InputErrorText>

              <InputErrorText errorMessage={errors.companyWebsite}>
                <TextField
                  fullWidth
                  sx={styles.input}
                  label="Company Website"
                  name="companyWebsite"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.companyWebsite}
                />
              </InputErrorText>

              <InputErrorText errorMessage={errors.dateToTakePhotos}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date to take photos"
                    value={
                      values.dateToTakePhotos
                        ? dayjs(values.dateToTakePhotos)
                        : null
                    }
                    onChange={(date) => {
                      const formattedDate = dayjs(date).format('YYYY-MM-DD');
                      setFieldValue('dateToTakePhotos', formattedDate);
                    }}
                    minDate={dayjs('2025-01-01')}
                    sx={styles.input}
                  />
                </LocalizationProvider>
              </InputErrorText>

              <Button
                variant="contained"
                color="primary"
                size="large"
                className="mt-6"
                type="submit"
                disabled={loading}
              >
                Submit
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </Dialog>
  );
}

const styles = {
  input: {
    borderRadius: '14px',
    borderColor: '#D1D5DB',
    backgroundColor: '#fff',
    '&:hover': { borderColor: '#D1D5DB' },
    '&:focus': { borderColor: '#D1D5DB' },
  },
};
