import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const HouseFeature = (props) => {
  const { isReverse, wrapperStyle, header, description, imageSvg } = props;

  return (
    <Grid
      container
      spacing={10}
      alignItems="center"
      justifyContent="space-between"
      sx={{
        ...styles.container,
        flexDirection: isReverse
          ? { xs: 'column-reverse', md: 'row-reverse' }
          : { xs: 'column', md: 'row' },
        ...wrapperStyle,
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          textAlign: { xs: 'center', md: 'left' },
          marginBottom: { xs: '16px', md: '0' },
        }}
      >
        <Typography variant="h1" sx={styles.header}>
          {header}
        </Typography>
        <Typography variant="body1" sx={styles.description}>
          {description}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={styles.imageContainer}>{imageSvg}</Box>
      </Grid>
    </Grid>
  );
};

export default HouseFeature;

HouseFeature.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageSvg: PropTypes.node.isRequired,
  isReverse: PropTypes.bool,
  wrapperStyle: PropTypes.object,
};

const styles = {
  container: {
    padding: '32px',
    ['@media (max-width:600px)']: {
      padding: '16px',
    },
  },
  header: {
    fontSize: '1.5rem !important',
    fontWeight: 600,
    textAlign: 'left',
    marginBottom: '16px',
    ['@media (max-width:600px)']: {
      fontSize: '1.25rem',
      textAlign: 'center',
    },
  },
  description: {
    fontSize: '1.125rem',
    fontWeight: 400,
    textAlign: 'left',
    marginBottom: '32px',
    ['@media (max-width:600px)']: {
      fontSize: '1rem',
      textAlign: 'center',
    },
  },
  imageContainer: {
    width: '100%',
    maxWidth: '600px', // Increased the maximum width for larger screens
    height: 'auto',
    ['@media (min-width:1200px)']: {
      maxWidth: '800px', // Further increase on larger screens (optional)
    },
    '& svg': {
      width: '100%',
      height: 'auto',
    },
  },
};
