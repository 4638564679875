import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import BgImage from '../../assets/virtual-tour/heroBg.svg';
import HouseFeature from '../../components/3d-virtual-tour/HouseFeature';
import IconFeature from '../../components/3d-virtual-tour/IconFeature';
import HeaderAndDescription from '../../components/3d-virtual-tour/HeaderAndDescription';
import HowItWorks from '../../components/3d-virtual-tour/HowItWorks';

import { ReactComponent as HeroImage } from '../../assets/virtual-tour/TourRoom.svg';
import { ReactComponent as StackedHouses } from '../../assets/virtual-tour/StackedHouses.svg';
import { ReactComponent as StackedHouses2 } from '../../assets/virtual-tour/StackedHouses2.svg';
import { ReactComponent as HouseWithMap } from '../../assets/virtual-tour/HouseWithMap.svg';
import { ReactComponent as HouseWithStreetView } from '../../assets/virtual-tour/HouseWithStreetView.svg';
import { ReactComponent as GetNoticedIcon } from '../../assets/virtual-tour/Eye.svg';
import { ReactComponent as RentVirtuallyIcon } from '../../assets/virtual-tour/HouseIcon.svg';
import { ReactComponent as FavouriteIcon } from '../../assets/virtual-tour/Favourite.svg';
import { ReactComponent as SittingRoomTour } from '../../assets/virtual-tour/SittingRoomTour.svg';
import { ReactComponent as WaveUpBgImage } from '../../assets/virtual-tour/WaveUp.svg';
import Testimonials from '../../components/3d-virtual-tour/Testimonials';
import ReserveSpotDialog from '../../components/3d-virtual-tour/ReserveSpotDialog';

const VirtualTour = () => {
  const [open, setOpen] = useState(false);
  return (
    <Box
      sx={{
        textAlign: 'center',
        scrollBehavior: 'smooth',
      }}
    >
      <ReserveSpotDialog open={open} setOpen={setOpen} />
      <Box sx={[styles.topSectionContainer, styles.container]}>
        <Box>
          <Typography component="h1" sx={styles.header}>
            Make your listing stand out with
            <br />
            FLATTTS 3D Virtual Tours!
          </Typography>
          <Typography sx={styles.description}>
            Want to give people a reason to stop and look around? Sign up for
            FLATTTS 3D
            <br /> listings to add virtual tours to any listing for free!
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={styles.button}
            aria-label="Reserve my spot for free"
            onClick={() => setOpen(true)}
          >
            Reserve My Spot For Free!
          </Button>
          <Typography sx={styles.disclaimer}>
            By signing up with FLATTTS, you agree to grant a member of the
            FLATTTS team access to your property for the purpose of
            <br /> capturing a 3D representation of your listing. This will be
            completely free for a limited time.
          </Typography>
        </Box>

        <Box sx={styles.heroImageContainer}>
          <HeroImage sx={styles.heroImage} />
        </Box>

        <Typography sx={styles.header}>
          Rent quicker with a Free 3D <br /> Virtual Tour!
        </Typography>

        <Typography sx={styles.highlightText}>Limited Time Offer</Typography>
        <Typography sx={styles.subText}>
          $250 Value, Absolutely Free (For Now)
        </Typography>
        <Button
          variant="contained"
          size="large"
          sx={styles.button}
          aria-label="Reserve my spot for free"
          onClick={() => setOpen(true)}
        >
          Reserve My Spot For Free!
        </Button>
      </Box>

      <Box sx={styles.container}>
        <HouseFeature
          imageSvg={<StackedHouses sx={styles.houseFeatureImage} />}
          header="Rent Your Property 2.7x Faster"
          description="Did you know that homes featuring 3D virtual tours rent 2.7 times faster than those without? Stand out in a competitive market by offering potential renters an immersive, interactive experience of your property."
        />
      </Box>
      <Box
        sx={[
          styles.container,
          {
            paddingBottom: '0',
          },
        ]}
      >
        <HeaderAndDescription
          header="Meet FLATTTS’ 3D Virtual Tours Service"
          description="It’s simple service that can give your listings a powerful boost!"
        />
      </Box>
      <Box sx={styles.container}>
        <HouseFeature
          imageSvg={<StackedHouses2 sx={styles.houseFeatureImage} />}
          header="Free to use."
          description="Sign up and we will contact you as soon as our team is ready to capture your rentals in 3D — it costs nothing."
          wrapperStyle={styles.houseFeature}
        />
        <HouseFeature
          imageSvg={<HouseWithStreetView sx={styles.houseFeatureImage} />}
          header="Easy to create."
          description=" With our quick process it takes no time to get your 3D listing live on the internet."
          isReverse
          wrapperStyle={styles.houseFeature}
        />
        <HouseFeature
          imageSvg={<HouseWithMap style={styles.houseFeatureImage} />}
          header="Hard to ignore."
          description="Listings on FLATTTS with an Interactive Floor Plan received, on average, 72% more shares than listings without an Interactive Floor Plan."
        />
      </Box>

      <Box
        sx={[
          styles.container,
          {
            backgroundColor: '#FFF9EB',
          },
        ]}
      >
        <HeaderAndDescription
          header="Virtual home tours and interactive floor plans seal the deal."
          description="The numbers just add up: 3D Home Tours and interactive floor plans make listings more interesting – and people more interested."
        />

        <Grid container spacing={4} sx={styles.iconGrid}>
          <Grid item xs={12} sm={4} sx={styles.gridItem}>
            <IconFeature
              svgIcon={<RentVirtuallyIcon />}
              header="Rent virtually."
              description="Listings on FLATTTS with an Interactive Floor Plan received, on average, 60% more views than listings without an Interactive Floor Plan.**"
              wrapperStyle={{
                borderRight: '1px solid #FEAE01',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} sx={styles.gridItem}>
            <IconFeature
              svgIcon={<GetNoticedIcon />}
              header="Get noticed."
              description="Listings on FLATTTS with an Interactive Floor Plan received, on average, 60% more views than listings without an Interactive Floor Plan.**"
              wrapperStyle={{
                borderRight: '1px solid #FEAE01',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4} sx={styles.gridItem}>
            <IconFeature
              svgIcon={<FavouriteIcon />}
              header="Be top of mind."
              description="Homes on FLATTTS with an Interactive Floor Plan were saved 79% more than homes without an Interactive Floor Plan.**"
              wrapperStyle={{
                padding: '2rem 2.5rem',
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.container}>
        <HeaderAndDescription showHeaderOnly header="How it Works" />
        <HowItWorks />
      </Box>

      <Box
        sx={[
          styles.container,
          {
            backgroundColor: '#FFF9EB',
          },
        ]}
      >
        <HeaderAndDescription
          header="Want to make your listings rise above the rest ?"
          description="Download the FLATTTS 3D Home app now."
        />
        <Button
          variant="contained"
          size="large"
          sx={styles.button}
          onClick={() => setOpen(true)}
        >
          Reserve My Spot For Free!
        </Button>
      </Box>

      <Box sx={styles.container}>
        <Box
          style={{
            backgroundImage: `url(${WaveUpBgImage})`,
            backgroundSize: 'cover',
            zIndex: 10,
          }}
        >
          <HeaderAndDescription
            header="Enhance the experience"
            description={
              <>
                Listings with 3D Home tours and interactive floor plans are made
                to be explored.
                <br />
                With every room you capture, you help people understand the
                layout and imagine <br /> what it would be like to call it home.
              </>
            }
          />
        </Box>

        <Box sx={styles.heroImageContainer}>
          <SittingRoomTour sx={styles.heroImage} />
        </Box>
      </Box>

      <Box
        sx={[
          styles.container,
          {
            backgroundColor: '#FFF9EB',
          },
        ]}
      >
        <Testimonials />
      </Box>
    </Box>
  );
};

export default VirtualTour;

const styles = {
  topSectionContainer: {
    backgroundImage: `url(${BgImage})`,
    textAlign: 'center',
    backgroundColor: '#FFF9EB',
  },
  container: {
    padding: '5rem 5%',
    '@media (max-width:780px)': {
      padding: '2.5rem 5%',
    },
  },
  header: {
    fontWeight: 600,
    fontSize: '1.5rem',
    textAlign: 'center',
    marginBottom: '16px',
  },
  description: {
    fontSize: '1.125rem',
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: '32px',
  },
  button: {
    backgroundColor: '#141313',
    color: '#fff',
    padding: '16px',
    borderRadius: '0.875rem',
    fontSize: '1.125rem',
    fontWeight: 600,
    textTransform: 'none',
    marginBottom: '32px',
    '&:hover': {
      backgroundColor: '#2b2b2b',
      color: '#00ffff',
    },
  },
  disclaimer: {
    fontSize: '0.875rem',
    fontWeight: 400,
    textAlign: 'center',
    color: '#6b6b6b',
  },
  highlightText: {
    fontSize: '1.5rem',
    fontWeight: 500,
    textAlign: 'center',
    color: '#FF3838',
    marginBottom: '16px',
  },
  subText: {
    fontSize: '1.125rem',
    fontWeight: 400,
    textAlign: 'center',
    color: '#141313',
    marginBottom: '40px',
  },
  heroImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '32px',
    ['@media (max-width:780px)']: {
      justifyContent: 'stretch',
      marginBottom: '0',
    },
  },
  heroImage: {
    width: '100%',
    maxWidth: '600px',
    ['@media (max-width:780px)']: {
      maxWidth: '100%',
    },
  },
  iconGrid: {
    flexDirection: { xs: 'column', sm: 'row' },
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2rem',
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: { xs: '2rem', sm: 0 },
  },
  houseFeatureImage: {
    width: '100%',
    maxWidth: '800px',
    height: 'auto',
    marginBottom: '2rem',
    '@media (max-width:780px)': {
      minWidth: '300px',
    },
  },
  houseFeature: {
    marginBottom: '2.5rem',
    '@media (min-width:780px)': {
      marginBottom: '1.25rem',
    },
  },
};
